// proceedJoinStep.js
'use strict';
import { goBack } from 'redux-first-history';

import { SET_OPERATION_DATA } from '../ActionTypes.js';

import getMeData from '../selector/getMeData.js';
import getOperationData from '../selector/getOperationData.js';
import getNetworkingData from '../selector/getNetworkingData.js';
import getRegisterData from '../selector/getRegisterData.js';

import { replace } from '../action/navigationAction.js';
import submitMeCreator from '../action/submitMeCreator.js';
import fetchMeCreator from '../action/fetchMeCreator.js';
import submitMeKyc from '../action/submitMeKyc.js';
import redoOcr from '../action/redoOcr.js';
import loadUrlQueryUtm from '../action/loadUrlQueryUtm.js';
import submitPasswordLoginData from '../action/submitPasswordLoginData.js';
import sendEmailBindOtp from '../action/sendEmailBindOtp.js';

import {
  Progress as JoinProgress,
  JoinField,
} from '../resource/joinConstants.js';
import { PasswordLoginEntry, BindEmail } from '../resource/authEntryType.js';

import { KYC_COMPLETED, KYC_FAILED } from '../serviceWorker/PusherEvents.js';
import * as MessageTypes from '../serviceWorker/AppMessageTypes.js';
import { sendMessageToSW } from '../serviceWorker/helpers.js';

/**
 * Click join form submit button
 * @kind action
 * @param {JoinProgress} {progress} - join progress.
 * @return {Promise} Action promise.
 */
const proceedJoinStep =
  ({ progress }) =>
  async (dispatch, getState) => {
    if (JoinProgress.PERSONAL_INFORMATION === progress) {
      const gender = getOperationData(
        getState(),
        ['join', 'draft', JoinField.GENDER],
        'value'
      );
      const referralCode =
        getOperationData(getState(), ['acquisition', 'manual'], 'code') || null;
      if (referralCode) {
        const { utm_medium, utm_term } = getMeData(getState(), 'utm') || {};
        const link = getOperationData(getState(), ['acquisition'], 'link');
        const shouldReplaceUtm = !(
          utm_medium === 'referral' && utm_term === `referral_${referralCode}`
        );
        if (shouldReplaceUtm) {
          await dispatch(
            loadUrlQueryUtm({
              searchParams: new URLSearchParams(
                link
                  ? link
                  : `?utm_medium=referral&utm_term=referral_${referralCode}`
              ),
            })
          );
        }
        // re-subscribe presence-enc-client@{CLIENT_ID}
        const clientId = getMeData(getState(), 'clientId');
        sendMessageToSW({
          type: MessageTypes.SUBSCRIBE_PRESENCE_CLIENT_CHANNEL,
          payload: {
            clientId,
          },
        });
        // re-subscribe presence-enc-user@{USER_ID}
        const meId = getMeData(getState(), 'id');
        if (meId) {
          sendMessageToSW({
            type: MessageTypes.SUBSCRIBE_PRESENCE_USER_CHANNEL,
            payload: {
              userId: meId,
            },
          });
        }
      }
      const country = getOperationData(
        getState(),
        ['join', 'draft', JoinField.COUNTRY],
        'value'
      );
      const username = getRegisterData(getState(), 'username');
      const password = getRegisterData(getState(), 'password');
      const email = getRegisterData(getState(), 'email');
      if (!getMeData(getState(), 'token')) {
        // register user first if user not authorized
        return dispatch(
          submitPasswordLoginData({
            username,
            password,
            shouldKeepUsername: true,
            entry: PasswordLoginEntry.JOIN,
            email,
          })
        );
      }
      if (!getMeData(getState(), 'accountLinks')?.email?.id) {
        // open email binding modal if email not bound
        dispatch({
          type: SET_OPERATION_DATA,
          payload: {
            selectPath: ['bindEmail', 'bind-entry'],
            data: BindEmail.JOIN,
          },
        });
        return dispatch(
          sendEmailBindOtp({
            email,
            isResend: false,
            shouldOpenVerifyModal: true,
          })
        );
      }
      await dispatch(
        submitMeCreator({
          gender,
          country,
          referralCode,
        })
      );
      const isFetched = getNetworkingData(
        getState(),
        ['submit', 'me', 'creator'],
        'isFetched'
      );
      const error = getNetworkingData(
        getState(),
        ['submit', 'me', 'creator'],
        'error'
      );
      if (isFetched && error) {
        return dispatch({ type: '' });
      }
      await dispatch(fetchMeCreator());
      return dispatch(redoOcr());
    }
    if (JoinProgress.UPLOAD_IDENTIFICATION === progress) {
      await dispatch(submitMeKyc());
      const isFetched = getNetworkingData(
        getState(),
        ['submit', 'me', 'kyc'],
        'isFetched'
      );
      const error = getNetworkingData(
        getState(),
        ['submit', 'me', 'kyc'],
        'error'
      );
      if (isFetched && error) {
        return dispatch({ type: '' });
      }
      return dispatch(
        replace(`/join/${JoinProgress.OCR_PROCESSING}${window.location.search}`)
      );
    }
    if (JoinProgress.OCR_RESULT === progress) {
      const kycId = getOperationData(getState(), ['join', 'kyc'], 'kycId');
      await dispatch(submitMeCreator({ kycId }));
      await dispatch(fetchMeCreator());
      const { kycId: creatorKycId } = getMeData(getState(), 'creator') || {};
      const isKycIdSet = creatorKycId === kycId;
      if (!isKycIdSet) {
        return dispatch({ type: '' });
      }
      return dispatch(
        replace(`/join/${JoinProgress.MANUAL_REVIEW}${window.location.search}`)
      );
    }
    if (JoinProgress.OCR_PROCESSING === progress) {
      const kycStatus = getOperationData(getState(), ['join', 'kyc'], 'status');
      if (KYC_FAILED === kycStatus) {
        return dispatch(
          replace(
            `/join/${JoinProgress.UPLOAD_IDENTIFICATION}${window.location.search}`
          )
        );
      }
      if (KYC_COMPLETED === kycStatus) {
        return dispatch(
          replace(`/join/${JoinProgress.OCR_RESULT}${window.location.search}`)
        );
      }
      return dispatch(goBack());
    }
    if (JoinProgress.MANUAL_REVIEW === progress) {
      return dispatch(goBack());
    }
    return dispatch({ type: '' });
  };

export default proceedJoinStep;
