// updateRegisterData.js
'use strict';
import { getUnixTime, parseISO } from 'date-fns';

import getRegisterData from '../selector/getRegisterData.js';
import fetchBindOAuth from '../action/fetchBindOAuth.js';
import checkRegisterAccountUsernameIsValid from '../action/checkRegisterAccountUsernameIsValid.js';
import verifyRegisterPasswordFormat from '../action/verifyRegisterPasswordFormat.js';
import isEmailFormatCorrect from '../resource/isEmailFormatCorrect.js';
import { MERGE_OPERATION_DATA, SET_NETWORKING_IDLE } from '../ActionTypes.js';

const EIGHTEEN_YEAR_SECS = 18 * 365 * 24 * 3600;
const selectPath = ['register'];
const avaliableFields = [
  'username',
  'password',
  'passwordChecking',
  'birthdateString',
  'email',
  'isEmailFormatCorrect',
  'emailPin',
  'isEmailAgreed',
  'isBindingPhone',
  'isFlightCheckPhoneVerify',
  'isBindingEmail',
  'usernameValidation',
  'passwordValidation',
  'isChangePhoneNumber',
];

/**
 * Update register data
 * @kind action
 * @param {string} {dataKey} - data key.
 * @param {any} {value} - value.
 * @param {boolean} {shouldAutoLogin} - whether or not to enable auto login while some criteria meet (e.g., the length of emailPin reaches 6)
 * @return {Promise} Action promise.
 */
const updateRegisterData =
  ({ dataKey, value, shouldAutoLogin }) =>
  async (dispatch, getState) => {
    if (!avaliableFields.includes(dataKey)) {
      return dispatch({ type: '' });
    }

    if ('birthdateString' === dataKey) {
      const birthdateUnix = value
        ? getUnixTime(parseISO(value))
        : getUnixTime(Date.now());
      const isUnderage = Date.now() / 1000 - birthdateUnix < EIGHTEEN_YEAR_SECS;
      return dispatch({
        type: MERGE_OPERATION_DATA,
        payload: {
          data: {
            birthdateString: value,
            birthdateUnix,
            isUnderage,
          },
          selectPath,
        },
      });
    }

    if ('email' === dataKey) {
      const formattedEmail = value?.replace(/[\b]/g, ' ');
      return dispatch({
        type: MERGE_OPERATION_DATA,
        payload: {
          data: {
            email: formattedEmail,
            isEmailFormatCorrect: formattedEmail
              ? isEmailFormatCorrect({
                  email: formattedEmail,
                })
              : undefined,
          },
          selectPath,
        },
      });
    }

    if ('emailPin' === dataKey) {
      if (shouldAutoLogin && 5 < value.length) {
        const email = getRegisterData(getState(), 'email');
        const isEmailAgreed = getRegisterData(getState(), 'isEmailAgreed');
        dispatch(
          fetchBindOAuth({
            oAuth: 'email',
            data: { pin: value, email, isEmailAgreed },
          })
        );
      } else {
        dispatch({
          type: SET_NETWORKING_IDLE,
          payload: {
            selectPath: ['register', 'bind', 'email'],
          },
        });
      }

      return dispatch({
        type: MERGE_OPERATION_DATA,
        payload: {
          data: {
            emailPin: value,
          },
          selectPath,
        },
      });
    }

    if ('username' === dataKey) {
      const username = value?.toLowerCase().replace(/[\b]/g, ' ');
      dispatch({
        type: MERGE_OPERATION_DATA,
        payload: {
          data: {
            username,
          },
          selectPath,
        },
      });
      return dispatch(checkRegisterAccountUsernameIsValid({ username }));
    }

    if ('password' === dataKey) {
      const password = value?.replace(/[\b]/g, ' ');
      dispatch({
        type: MERGE_OPERATION_DATA,
        payload: {
          data: {
            password,
          },
          selectPath,
        },
      });
      return dispatch(verifyRegisterPasswordFormat({ password }));
    }

    return dispatch({
      type: MERGE_OPERATION_DATA,
      payload: {
        data: {
          [dataKey]: value,
        },
        selectPath,
      },
    });
  };

export default updateRegisterData;
