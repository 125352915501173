// submitPasswordLoginData.js
'use strict';
import fetch from '../resource/customFetch.js';
import { getHeaders } from '../resource/fetchOptionHeader.js';
import getResourceUrl from '../resource/getResourceUrl.js';
import handleFetchError from '../resource/handleFetchError.js';
import {
  SET_NETWORKING_FETCHING,
  SET_NETWORKING_SUCCESS,
  SET_NETWORKING_ERROR,
  MERGE_OPERATION_DATA,
  SET_OPERATION_DATA,
} from '../ActionTypes.js';
import handleLoginToken from '../action/handleLoginToken.js';
import addModal from '../action/addModal.js';
import mergeMeData from '../action/mergeMeData.js';
import removeModals from '../action/removeModals.js';
import { PasswordLoginEntry, BindEmail } from '../resource/authEntryType.js';
import setPasswordVerifyStatus from '../action/setPasswordVerifyStatus.js';
import fetchAccessToken, { viaTypes } from '../action/fetchAccessToken.js';
import getMeData from '../selector/getMeData.js';
import setTurnstileToken from '../action/setTurnstileToken.js';
import loadUrlQueryUtm from '../action/loadUrlQueryUtm.js';
import getOperationData from '../selector/getOperationData.js';
import getRegisterData from '../selector/getRegisterData.js';
import sendEmailBindOtp from '../action/sendEmailBindOtp.js';
import updateLoginData from '../action/updateLoginData.js';

/**
 * Submit account login/register
 * @kind action
 * @param {string} {username} - login|register username.
 * @param {string} {password} - login|register password.
 * @param {boolean} {shouldKeepUsername} - login|register shouldKeepUsername.
 * @param {string} {entry} - login || register.
 * @return {Promise} Action promise.
 */
const submitPasswordLoginData =
  ({ username, password, shouldKeepUsername, entry, email }) =>
  async (dispatch, getState) => {
    const isLogin = entry === PasswordLoginEntry.LOGIN;
    const isPasswordSetting = entry === PasswordLoginEntry.PASSWORD_SETTING;
    const referralCode = getOperationData(
      getState(),
      ['acquisition', 'manual'],
      'code'
    );
    if (PasswordLoginEntry.REGISTER === entry && referralCode) {
      const { utm_medium, utm_term } = getMeData(getState(), 'utm') || {};
      const link = getOperationData(getState(), ['acquisition'], 'link');
      const shouldReplaceUtm = !(
        utm_medium === 'referral' && utm_term === `referral_${referralCode}`
      );
      if (shouldReplaceUtm) {
        await dispatch(
          loadUrlQueryUtm({
            searchParams: new URLSearchParams(
              link
                ? link
                : `?utm_medium=referral&utm_term=referral_${referralCode}`
            ),
          })
        );
      }
    }

    const selectPath = ['passwordLogin', entry];
    const fetchOptions = {
      method: 'POST',
      headers: {
        ...getHeaders(),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ username, password }),
    };
    const url = getResourceUrl({ endpoint: '/login/password' });
    if (isLogin) {
      url.searchParams.append('login_only', 1);
    }
    dispatch({ type: SET_NETWORKING_FETCHING, payload: { selectPath } });

    try {
      await dispatch(setTurnstileToken());
      const turnstileToken = getOperationData(
        getState(),
        ['turnstile'],
        'token'
      );
      if (turnstileToken)
        fetchOptions.headers['X-Turnstile-Token'] = turnstileToken;
      // eslint-disable-next-line no-empty
    } catch (e) {}

    try {
      const response = await fetch(url.href, fetchOptions);
      if (!response.ok) {
        await handleFetchError({ response });
      }

      if (isLogin && 202 === response.status) {
        dispatch({ type: SET_NETWORKING_SUCCESS, payload: { selectPath } });
        return dispatch(
          addModal({
            id: 'StartRegisterAlert',
            isHigherThanAll: true,
            transitionStatus: 'coming',
          })
        );
      }

      if (isLogin) {
        const defaultUsername = shouldKeepUsername ? username : '';
        dispatch(
          mergeMeData({ field: 'defaultUsername', value: defaultUsername })
        );
      }

      let token;
      const { refresh_token: refreshToken } = await response.json();
      if (refreshToken) {
        await dispatch(
          fetchAccessToken({ refreshToken, via: viaTypes.JWT_LOGIN })
        );
        token = getMeData(getState(), 'token');
      }

      if (isPasswordSetting) {
        dispatch(setPasswordVerifyStatus({ hasError: false }));
        dispatch(
          addModal({ id: 'PasswordSetting', transitionStatus: 'coming' })
        );
        dispatch(
          removeModals({ ids: ['PasswordVerify'], transitionStatus: 'closing' })
        );
      } else {
        if (entry === PasswordLoginEntry.REGISTER) {
          dispatch({
            type: MERGE_OPERATION_DATA,
            payload: {
              selectPath: ['register'],
              data: {
                isRegisterJustCompleted: true,
              },
            },
          });

          if (email) {
            const isEmailAgreed = getRegisterData(getState(), 'isEmailAgreed');
            dispatch(sendEmailBindOtp({ email, isEmailAgreed }));
          }
        }
        if (entry === PasswordLoginEntry.JOIN) {
          // Username registration on creator onboarding flow, needs open email verify modal after user registered
          dispatch({
            type: SET_OPERATION_DATA,
            payload: {
              selectPath: ['bindEmail', 'bind-entry'],
              data: BindEmail.JOIN,
            },
          });
          dispatch(
            sendEmailBindOtp({
              email,
              isResend: false,
              shouldOpenVerifyModal: true,
            })
          );
        }
        dispatch(handleLoginToken({ token }));
      }
      return dispatch({
        type: SET_NETWORKING_SUCCESS,
        payload: { selectPath },
      });
    } catch (error) {
      // let textKey;
      try {
        const errorObject = JSON.parse(error.message);
        error.status = errorObject.status;
        // eslint-disable-next-line no-empty
      } catch (_) {}
      if (error?.status === 400) {
        if (isPasswordSetting) {
          dispatch(setPasswordVerifyStatus({ hasError: true }));
        } else {
          dispatch(
            updateLoginData({
              dataKey: 'passwordValidation',
              value: 'wrong_username_password',
            })
          );
        }
      }
      return dispatch({
        type: SET_NETWORKING_ERROR,
        payload: { error, selectPath },
      });
    }
  };

export default submitPasswordLoginData;
