// sendEmailBindOtp.js
'use strict';
import fetch from '../resource/customFetch.js';
import { AuthId } from '../resource/loginRegisterConstants.js';
import { getHeaders } from '../resource/fetchOptionHeader.js';
import getResourceUrl from '../resource/getResourceUrl.js';
import handleFetchError from '../resource/handleFetchError.js';
import fetchEmailDidYouMean from '../resource/fetchEmailDidYouMean.js';
import getCurrentLanguage from '../resource/getCurrentLanguage.js';
import { localeConvertor } from '../resource/i18n.js';
import { register as registerModal } from '../resource/modalId.js';

import getMeData from '../selector/getMeData.js';
import getOperationData from '../selector/getOperationData.js';
import getEmailCooldownTimestamp from '../selector/getEmailCooldownTimestamp.js';
import getRemoteConfigData from '../selector/getRemoteConfigData.js';

import setTurnstileToken from '../action/setTurnstileToken.js';
import mergeMeData from '../action/mergeMeData.js';
import updateRegisterData from '../action/updateRegisterData.js';
import addModal from '../action/addModal.js';
import removeModals from '../action/removeModals.js';

import {
  MERGE_OPERATION_DATA,
  SET_NETWORKING_IDLE,
  SET_NETWORKING_FETCHING,
  SET_NETWORKING_SUCCESS,
  SET_NETWORKING_ERROR,
} from '../ActionTypes.js';
import { RESEND_EMAIL_VERIFIED_CODE_SEC } from '../RemoteConfigKeys.js';

/**
 * Send email bind otp
 * @kind action
 * @param {string} {email} - email
 * @param {bool} {isEmailAgreed} - is email agreed.
 * @param {bool} {isResend} - is function call in verify modal.
 * @param {bool} {shouldOpenVerifyModal} - should open verify modal.
 * @return {Promise} Action promise.
 */
const sendEmailBindOtp =
  ({ email, isEmailAgreed, isResend, shouldOpenVerifyModal = false } = {}) =>
  async (dispatch, getState) => {
    const state = getState();
    const selectPath = ['register', 'emailOtp', email];

    const token = getMeData(state, 'token');
    const isEmailFormatCorrect = getOperationData(
      state,
      ['register'],
      'isEmailFormatCorrect'
    );
    if (!token || !isEmailFormatCorrect) {
      return dispatch({ type: '' });
    }

    const sentCount = getOperationData(state, selectPath, 'sentCount') || 0;
    const emailCooldownTimestamp = getEmailCooldownTimestamp(
      state,
      ['register', 'emailOtp'],
      email
    );
    if (sentCount && Date.now() < emailCooldownTimestamp) {
      if (!isResend && shouldOpenVerifyModal) {
        dispatch(
          addModal({
            id: registerModal.EMAIL_VERIFY,
            isHigherThanAll: true,
            transitionStatus: 'coming',
          })
        );
        return dispatch(
          removeModals({
            ids: [registerModal.REGISTER_EMAIL],
            transitionStatus: 'leaving',
          })
        );
      } else {
        return dispatch({ type: '' });
      }
    }

    const fetchOptions = {
      method: 'POST',
      headers: {
        ...getHeaders(),
        Authorization: `Bearer ${token}`,
      },
    };

    const lang = localeConvertor({
      locale: getCurrentLanguage(),
      isISO639: true,
    });
    const nextUrl = new URL('/verifyEmail', location.origin);
    nextUrl.searchParams.set('email', email);
    nextUrl.searchParams.set('lang', lang);

    const url = getResourceUrl({ endpoint: '/verify/email' });
    url.searchParams.set('email', email);
    url.searchParams.set('lang', lang);
    url.searchParams.set('next', nextUrl.href);
    url.searchParams.set('magiclink', 1);

    dispatch({ type: SET_NETWORKING_FETCHING, payload: { selectPath } });

    try {
      await dispatch(setTurnstileToken());
      const turnstileToken = getOperationData(
        getState(),
        ['turnstile'],
        'token'
      );
      if (turnstileToken)
        fetchOptions.headers['X-Turnstile-Token'] = turnstileToken;
      // eslint-disable-next-line no-empty
    } catch (e) {}

    try {
      let response = await fetch(url.href, fetchOptions);

      if (!response.ok) {
        response = await handleFetchError({
          response,
          dispatch,
          getState,
          fetchOptions,
          fetchUrl: url,
        });
      }

      // Proxied mailgun api.
      if (1 === sentCount) {
        try {
          const didYouMean = await fetchEmailDidYouMean({ email });
          if (didYouMean && email !== didYouMean) {
            dispatch({
              type: MERGE_OPERATION_DATA,
              payload: { selectPath, data: { didYouMean } },
            });
          }
          // eslint-disable-next-line no-empty
        } catch (_) {}
      }
      dispatch(
        updateRegisterData({ dataKey: 'isEmailAgreed', value: isEmailAgreed })
      );
      if (shouldOpenVerifyModal) {
        if (!isResend) {
          dispatch(
            addModal({
              id: registerModal.EMAIL_VERIFY,
              isHigherThanAll: true,
              transitionStatus: 'coming',
            })
          );
        }
        dispatch(
          removeModals({
            ids: [registerModal.REGISTER_EMAIL],
            transitionStatus: 'leaving',
          })
        );
      }
      const cooldownSeconds = getRemoteConfigData(
        getState(),
        RESEND_EMAIL_VERIFIED_CODE_SEC
      );
      dispatch({
        type: MERGE_OPERATION_DATA,
        payload: {
          selectPath,
          data: {
            sentCount: sentCount + 1,
            cooldownUnit: cooldownSeconds,
          },
        },
      });
      dispatch(
        mergeMeData({
          field: 'accountLinks',
          value: {
            [AuthId.EMAIL_UNVERIFIED]: {
              id: email,
            },
          },
        })
      );
      return dispatch({
        type: SET_NETWORKING_SUCCESS,
        payload: { selectPath },
      });
    } catch (error) {
      setTimeout(
        () => dispatch({ type: SET_NETWORKING_IDLE, payload: { selectPath } }),
        1500 // TODO: remote config
      );
      return dispatch({
        type: SET_NETWORKING_ERROR,
        payload: { selectPath, error },
      });
    }
  };

export default sendEmailBindOtp;
